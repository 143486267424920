import React, { Component } from "react";
import { Icon } from "@iconify/react";
import angularIcon from "@iconify/icons-logos/angular-icon";
import reactIcon from "@iconify/icons-logos/react";
import vueIcon from "@iconify/icons-logos/vue";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typedText: "",
      command: "whoami",
      typingIndex: 0,
    };
  }

  componentDidMount() {
    this.startTypingEffect();
  }

  startTypingEffect = () => {
    const { command } = this.state;
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index < command.length) {
        this.setState((prevState) => ({
          typedText: prevState.typedText + command[index],
          typingIndex: prevState.typingIndex + 1,
        }));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust speed of typing
  };

  render() {
    const { typedText } = this.state;

    // Reusable styles
    const terminalStyles = {
      fontFamily: "'Ubuntu Mono', monospace",
      backgroundColor: "#300a24",
      color: "#ffffff",
      height: "400px",
      width: "711px",
      borderRadius: "15px 15px 0 0",
      fontSize: "16px",
      padding: "0",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    };

    const topBarStyles = {
      borderBottom: "solid 1px black",
      backgroundColor: "#222222",
      height: "30px",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      justifyContent: "space-between",
    };

    const { sharedBasicInfo, resumeBasicInfo } = this.props;

    const profilePic = sharedBasicInfo ? `images/${sharedBasicInfo.image}` : "";
    const sectionName = resumeBasicInfo?.section_name?.about || "About Me";
    const hello = resumeBasicInfo?.description_header || "Hello!";
    const about = resumeBasicInfo?.description || "This is my portfolio.";

    const techIcons = [
      { icon: angularIcon, color: "#DD0031" },
      { icon: reactIcon, color: "#61DAFB" },
      { icon: vueIcon, color: "#42B883" },
    ];

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "white" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            {/* Profile Section */}
            {/* <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="250px"
                    src={profilePic}
                    alt="Avatar placeholder"
                  />
                  {techIcons.map((tech, index) => (
                    <Icon
                      key={index}
                      icon={tech.icon}
                      style={{
                        fontSize: "400%",
                        margin: "9% 5% 0 5%",
                        color: tech.color,
                      }}
                    />
                  ))}
                </span>
              </div>
            </div> */}

            {/* Terminal Section */}
            <div style={terminalStyles}>
              {/* Top Bar */}
              <div style={topBarStyles}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    color: "#ccc8ca",
                  }}
                >
                  
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  {["minus", "square", "times"].map((icon, idx) => (
                    <div
                      key={idx}
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#373737",
                      }}
                    >
                      <i
                        className={`far fa-${icon}`}
                        style={{ color: "white", fontSize: "50%" }}
                      ></i>
                    </div>
                  ))}
                </div>
              </div>

              {/* Main Content */}
              <div
                style={{
                  flex: 1,
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Header */}
                <div
                  style={{
                    color: "#00ff00",
                    marginBottom: "10px",
                    display: "flex",
                  }}
                >
                  <span style={{ color: "#279d68" }}>Ahsan Ahmad</span>
                  <span style={{ color: "#124674" }}>:~/portfolio</span>
                  <span style={{ color: "#ccc8ca", marginLeft: "5px" }}>$</span>
                  <span style={{ color: "#ccc8ca", marginLeft: "5px" }}>
                    {typedText}
                  </span>
                </div>
                {/* Additional About Content */}
                <p style={{ color: "#ccc8ca" }}>{about}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
