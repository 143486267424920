import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="header-container">
          {/* Logo */}
          <div className="logo">
            <div className="icon">
              <i className="fas fa-cog"></i>
            </div>
            <span className="text">Ahsan</span>
          </div>

          {/* Navigation */}
          <nav className="nav">
            <a href="#home" className="nav-item active">
              HOME
            </a>
            <a href="#about" className="nav-item">
              ABOUT
            </a>
            <a href="#resume" className="nav-item">
              RESUME
            </a>
            <a href="#portfolio" className="nav-item">
              PORTFOLIO
            </a>
            <a href="#blog" className="nav-item">
              BLOG
            </a>
            <a href="#contact" className="nav-item">
              CONTACT
            </a>
          </nav>

          {/* Divider and Contact */}
          <div className="contact">
            <div className="divider"></div>
            <a href="tel:+19712341508" className="contact-link">
              <i className="fas fa-phone-alt"></i>
              <span className="phone-number">+923000000000</span>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
