import React, { Component } from "react";

class LandingPage extends Component {
  render() {
    return (
      <div className="landing-page">
        <div className="left-section">
          <h2 className="greeting">Hello, I'm</h2>
          <h1 className="name" style={{fontSize:'80px'}}>Ahsan Ahmad</h1>
        </div>
        <div className="right-section">
          <img
            src="images/myProfile.png"
            alt="Landing"
            className="landing-image"
          />
        </div>
      </div>
    );
  }
}

export default LandingPage;
